import React from 'react';
import './Home.css';

export default function Home() {
    return (
        <div>
            <p>
                Dr Irene Dedoussi is an Associate Professor in Sustainable
                Aviation and Energy in the{' '}
                <a href="https://www.eng.cam.ac.uk/">
                    Department of Engineering
                </a>{' '}
                of the{' '}
                <a href="https://www.cam.ac.uk/">University of Cambridge</a> and
                a member of the{' '}
                <a href="https://whittle.eng.cam.ac.uk/">Whittle Laboratory</a>.
                Irene’s work aims to advance our understanding of the
                environmental impacts of combustion emissions from aviation and
                other sectors, and contribute to their sustainable growth. Prior
                to joining the University of Cambridge, Irene was an Associate
                Professor in the{' '}
                <a href="https://www.tudelft.nl/en/ae">
                    Faculty of Aerospace Engineering
                </a>{' '}
                of <a href="https://www.tudelft.nl/en">TU Delft</a>, with which
                she remains affiliated. Irene is a fellow of the{' '}
                <a href="https://nae.nl/en/fellows/">
                    Netherlands Academy of Engineering
                </a>{' '}
                and a member of the Young Adacemy (
                <a href="https://www.dejongeakademie.nl/en/members__/members/2238454.aspx?t=Irene-Dedoussi-">
                    De Jonge Akademie
                </a>
                ) of the Royal Dutch Academy of Arts and Sciences (KNAW).
            </p>
            <p>
                Before joining TU Delft in 2019 as a tenure-track Assistant
                Professor, Irene was an interim Postdoctoral Associate at MIT.
                Additionally, she has worked on aeroacoustics at DLR Berlin{' '}
                <a href="https://www.dlr.de/at/en/desktopdefault.aspx">
                    Institute of Propulsion Technology
                </a>
                , and on aircraft engine performance analysis and fleet-wide
                engine maintenance planning at{' '}
                <a href="https://www.lufthansa-technik.com/">
                    Lufthansa Technik
                </a>
                .
            </p>
            <p>
                Irene hails from Athens, Greece, and has lived in the US, UK,
                Germany, and the Netherlands.
            </p>
            <br />
            <h4>Education</h4>
            <ul>
                <li>2014-2018, PhD, Aeronautics and Astronautics, MIT</li>
                <li>2012-2014, SM, Aeronautics and Astronautics, MIT</li>
                <li>
                    2008-2012, BA (Hons), MEng (Hons w/ Distinction),
                    Engineering, University of Cambridge
                </li>
            </ul>
            <br />
            <h4>Selected Awards and Recognitions</h4>
            <ul>
                <li>
                    2023: Elected Fellow of the Netherlands Academy of
                    Engineering{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://nae.nl/en/fellows/"
                    >
                        {' '}
                        [link]
                    </a>
                </li>
                <li>
                    2023: Best Climate Science paper of 2022 (PhD candidate Jin
                    Maruhashi), TU Delft Climate Action{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.tudelft.nl/en/2023/tu-delft/tu-delft-presents-the-eight-best-climate-action-energy-papers"
                    >
                        {' '}
                        [link]
                    </a>
                </li>
                <li>
                    2021: Appointed member of De Jonge Akademie, KNAW (Young
                    Adacemy of the Royal Dutch Academy of Arts and Sciences){' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.knaw.nl/en/news/news/ten-new-members-for-the-young-academy-1"
                    >
                        {' '}
                        [link]
                    </a>
                    {', '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://vimeo.com/690884700"
                    >
                        {' '}
                        [intro video]{' '}
                    </a>
                </li>
                <li>
                    2020: Veni Grant (Early Career Talent programme), Dutch
                    Research Council (NWO){' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.tudelft.nl/en/2020/lr/two-veni-grants-for-aerospace-engineering-researchers"
                    >
                        {' '}
                        [link]{' '}
                    </a>
                </li>
                <li>
                    2020: Forbes 30 under 30, Science and Healthcare Europe list{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.forbes.com/profile/irene-dedoussi/?list=30under30-europe-science-healthcare#7ea5c5f17861"
                    >
                        {' '}
                        [link]{' '}
                    </a>
                </li>
                <li>
                    2018: Research pitch competition, 2<sup>nd</sup> place,
                    Women’s Unconference, MIT Alumni Association{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://news.mit.edu/2018/alumnae-unite-mit-womens-unconference-0319"
                    >
                        {' '}
                        [link]{' '}
                    </a>
                </li>
                <li>
                    2016-17: Martin Family Fellowship for Sustainability,
                    awarded to 10 MIT graduate students{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://martin-fellows.mit.edu/fellows/current-fellows/"
                    >
                        {' '}
                        [link]{' '}
                    </a>
                </li>
                <li>
                    2016-17: Amelia Earhart Fellowship, awarded to 35 women
                    globally{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://membership.zonta.org/Portals/0/Membership/Tools/AwardScholarshipFellowshipTools/2016AEFellowsBios.pdf"
                    >
                        {' '}
                        [link]{' '}
                    </a>
                </li>
                <li>
                    2016: “Best article of 2015” in{' '}
                    <i>Environmental Research Letters</i>, awarded for VW
                    emissions study{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://cms.iopscience.org/d90eee39-00ba-11e6-a415-496188f8a415/Highlights-2015-pdf.pdf?guest=true"
                    >
                        {' '}
                        [link]{' '}
                    </a>
                </li>
                <li>
                    2015-16: George and Marie Vergottis Fellowship, awarded to
                    one Greek graduate student at MIT
                </li>
                <li>
                    2015: MIT Graduate Woman of Excellence Award, awarded to 50
                    graduate MIT women for leadership and service contributions{' '}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://oge.mit.edu/wp-content/uploads/2015/10/GWOE15-DedoussiandPosterW.jpg"
                    >
                        {' '}
                        [link]{' '}
                    </a>
                </li>
                <li>
                    2014: 2nd Place, 10<sup>th</sup> annual international Joseph
                    A. Hartman Student Paper Competition
                </li>
                <li>
                    2013: Young Woman of the Year, Ariston Foundation, awarded
                    to one Greek woman studying in the USA
                </li>
                <li>
                    2012: Admitted member of “The Foundation of the College of
                    St John the Evangelist in the University of Cambridge”, for
                    outstanding performance during undergraduate studies
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.joh.cam.ac.uk/admission-scholars-2012-11"
                    >
                        {' '}
                        [link]{' '}
                    </a>
                </li>
                <li>
                    2008-12: George and Marie Vergottis Scholarship, University
                    of Cambridge
                </li>
            </ul>
        </div>
    );
}
