import React from 'react';
import './Teaching.css';

export default function Teaching() {
    return (
        <div>
            <h4>At TU Delft</h4>
            <ul>
                <li>
                    <b>AE4462 Aircraft Emissions and Climate Effects</b> (MSc
                    level, lecturer), Spring 2019, 2020, 2021, 2022, 2023, 2024
                </li>
                <li>
                    <b>AE2223-I Test, Analysis & Simulation</b> (BSc level,
                    project tutor), Spring 2019, 2020, 2021, 2022, 2023
                </li>
                <li>
                    <b>AE3200 Design Synthesis Exercise</b> (BSc level, project
                    tutor), Spring 2020, 2022
                </li>
                <li>
                    <b>
                        Sustainable Aviation: The Route to Climate-Neutral
                        Aviation
                    </b>{' '}
                    (
                    <a href="https://www.edx.org/course/sustainable-aviation">
                        edX MOOC
                    </a>
                    , general public level, lecturer), launched Spring 2021
                </li>
            </ul>
            <br />
            <h4>At MIT</h4>
            <ul>
                <li>
                    <b>16.715 Aerospace, Energy, and the Environment</b> (SM
                    level, guest lecturer) Fall 2017, 2018
                </li>
                <li>
                    <b>MIT Kaufman Teaching Certificate Program</b>, Summer 2015
                </li>
            </ul>
        </div>
    );
}
