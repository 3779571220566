import React from 'react';
import './Research.css';
import Accordion from 'react-bootstrap/Accordion';
import Prelude from './Prelude';
import Team from './Team';
import { Areas } from './Areas';

export default function Research() {
    return (
        <div>
            <Prelude />
            <Accordion className="my-4">
                {Areas.map((area, idx) => (
                    <Accordion.Item
                        className="bg-transparent"
                        key={idx.toString()}
                        eventKey={idx.toString()}
                    >
                        <Accordion.Header as={'h4'}>
                            {area.title}
                        </Accordion.Header>
                        <Accordion.Body>{area.content}</Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
            <Team />
        </div>
    );
}
