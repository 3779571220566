import React, { Component } from 'react';
import './App.scss';
import Header from '../Header/Header';
import Navigation from '../Navigation/Navigation';
import Footer from '../Footer/Footer';
import Container from 'react-bootstrap/Container';
import { BrowserRouter, Route } from 'react-router-dom';

class App extends Component {
    render() {
        return (
            <Container className="App">
                <Header />
                <hr />
                <BrowserRouter>
                    <Route path="/:key?" component={Navigation} />
                </BrowserRouter>
                <hr />
                <Footer />
            </Container>
        );
    }
}

export default App;
