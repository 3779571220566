import React from 'react';
import PropTypes from 'prop-types';
import './Links.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { camelCase } from 'lodash/string';
import ListGroup from 'react-bootstrap/ListGroup';

export default function Links(props) {
    const { links } = props;
    return (
        <ListGroup variant="flush" className="fw-bold">
            {links.map((link) => (
                <ListGroup.Item
                    key={camelCase(link.title)}
                    className="text-truncate"
                >
                    <a
                        href={link.url}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />{' '}
                        {link.title}
                    </a>
                </ListGroup.Item>
            ))}
        </ListGroup>
    );
}

Links.propTypes = {
    links: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
        })
    ),
};
