import React from 'react';
import './Contact.css';

export default function Contact() {
    return (
        <p>
            <b>Dr Irene C. Dedoussi</b>
            <br />
            <br />
            <b>Office: </b>{' '}
            <a href="https://map.cam.ac.uk/Whittle+Laboratory#52.211220,0.093260,17">
                Whittle Laboratory
            </a>
            , Room WEG-24
            <br />
            <br />
            <b>Telephone: </b>
            +44 (0)1223 746973
            <br />
            <br />
            <b>Address:</b>
            <br />
            1 JJ Thomson Avenue,
            <br />
            Cambridge, CB3 0DY
            <br />
            United Kingdom
            <br />
            <br />
            <b>
                Email: <a href="mailto: icd23@cam.ac.uk">icd23@cam.ac.uk</a>
            </b>
        </p>
    );
}
