import React from 'react';
import PropTypes from 'prop-types';
import './Panel.css';
import { capitalize } from 'lodash/string';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Panel(props) {
    const { title, text, media } = props;
    return (
        <Container className="mb-3">
            <h3 className="pb-3">{capitalize(title)}</h3>
            <Row className="panel-body">
                <Col md={9}>{text}</Col>
                <Col md={3}>{media}</Col>
            </Row>
        </Container>
    );
}

Panel.propTypes = {
    title: PropTypes.string.isRequired,
    text: PropTypes.node.isRequired,
    media: PropTypes.node,
};
