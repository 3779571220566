import React from 'react';
import './Footer.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';

export default function Footer() {
    return (
        <footer className="pb-4 text-secondary">
            <Container>
                <Row>
                    <Col>
                        <span>Irene C. Dedoussi &copy; 2024</span>
                        <span className="mx-2">|</span>
                        <span>
                            <a
                                href="https://twitter.com/irenededoussi"
                                target="_blank"
                                rel="noopener noreferrer"
                                key={0}
                            >
                                <FontAwesomeIcon icon={faTwitter} />
                            </a>
                        </span>
                        <span className="mx-1" />
                        <span>
                            <a
                                href="https://www.linkedin.com/in/irene-dedoussi/"
                                target="_blank"
                                rel="noopener noreferrer"
                                key={0}
                            >
                                <FontAwesomeIcon icon={faLinkedin} />
                            </a>
                        </span>
                    </Col>

                    <Col className="text-end">University of Cambridge</Col>
                </Row>
            </Container>
        </footer>
    );
}
