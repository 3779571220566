import React from 'react';
import PropTypes from 'prop-types';
import './Navigation.css';
import Panel from '../Panel/Panel';
import contentItems from '../../content/Content.jsx';
import { capitalize } from 'lodash/string';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import { Link } from 'react-router-dom';

const navItems = contentItems.map((contentItem) => (
    <Nav.Item key={contentItem.key}>
        <Nav.Link
            as={Link}
            to={`/${contentItem.key}`}
            eventKey={contentItem.key}
        >
            {capitalize(contentItem.key)}
        </Nav.Link>
    </Nav.Item>
));

const navPanes = contentItems.map((contentItem) => (
    <Tab.Pane key={contentItem.key} eventKey={contentItem.key}>
        <Panel
            title={contentItem.key}
            text={contentItem.text}
            media={contentItem.media}
        />
    </Tab.Pane>
));

export default function Navigation(props) {
    const matchKey = props.match.params.key;
    return (
        <Tab.Container
            className="px-0"
            defaultActiveKey={matchKey ? matchKey : contentItems[0].key}
        >
            <Row>
                <Col md={2} className="px-0 mb-4">
                    <Nav variant="pills" className="flex-column">
                        {navItems}
                    </Nav>
                </Col>
                <Col md={10} className="views">
                    <Tab.Content>{navPanes}</Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
}

Navigation.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({ key: PropTypes.string }),
    }),
};
