import React from 'react';

export default function Prelude() {
    return (
        <div>
            <div>
                <p>
                    Sustainable transportation and energy systems are key to
                    addressing the environmental challenges we face. Our
                    research aims to quantify the environmental impacts of
                    aviation as well as other transportation and energy sectors,
                    and to advance our understanding of how emerging energy
                    sources and technologies can effect change. We take a
                    multi-disciplinary, systems-level approach to analyse
                    mitigation strategies, which include assessments of
                    sustainable engineering design, alternative fuels,
                    energy-efficient operations, and potential regulatory
                    policies.
                </p>
                <i>
                    Interested PhD candidates and postdocs are welcome to{' '}
                    <a href="mailto: icd23@cam.ac.uk">get in touch</a>.{' '}
                </i>
            </div>
        </div>
    );
}
