import React from 'react';
import PropTypes from 'prop-types';
import aviationEmissionsAndTheirAtmosphericImpactsImg from './Assets/aviation-emissions-and-their-atmospheric-impacts.png';
import atmosphericImpactsOfCombustionEmissionsSectorsImg from './Assets/atmospheric-impacts-of-combustion-emissions-sectors.png';
import dataDrivenEnvironmentalAssessmentsImg from './Assets/data-driven-environmental-assessments.png';

function EmojiItem(props) {
    const { emoji, label, children } = props;
    return (
        <div>
            <span className="me-2" role="img" aria-label={label}>
                {emoji}
            </span>
            {children}
        </div>
    );
}

EmojiItem.propTypes = {
    emoji: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export const Areas = [
    {
        title: 'Understanding the atmospheric impacts of combustion emissions',
        content: (
            <div>
                <img
                    className="img-responsive img-thumbnail ms-2 mt-1 mb-2"
                    align="right"
                    src={atmosphericImpactsOfCombustionEmissionsSectorsImg}
                    alt="Atmospheric Impacts Of Combustion Emissions Sectors"
                    width="50%"
                />
                <p>
                    Combustion by-products from energy, industrial and
                    transportation sectors impact the atmospheric composition,
                    leading to air pollution, nitrogen deposition, and adverse
                    climate impacts. We assess the atmospheric and human health
                    impacts of different sectors and how these impacts spread
                    from the emission location. Since aviation emissions occur
                    at high altitudes, the air transport sector forms a
                    special-interest category for our research, as we aim to
                    understand the unique underlying physical and chemical
                    mechanisms of this sector&apos;s impacts.
                </p>
                <div>
                    <EmojiItem emoji="📄" label="References">
                        <a
                            href="https://doi.org/10.5194/acp-22-14253-2022"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Maruhashi et al. (2022)
                        </a>
                        ,{' '}
                        <a
                            href="https://doi.org/10.1088/1748-9326/abb2c5"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Quadros et al. (2020)
                        </a>
                        ,{' '}
                        <a
                            href="https://doi.org/10.1038/s41586-020-1983-8"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Dedoussi et al. (2020)
                        </a>
                        ,{' '}
                        <a
                            href="https://doi.org/10.1016/j.atmosenv.2014.10.033"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Dedoussi and Barrett (2014)
                        </a>
                    </EmojiItem>
                    <EmojiItem emoji="🌐" label="Press">
                        <a
                            href="https://www.tudelft.nl/en/2020/lr/aviation-degrades-air-quality-how-much-depends-on-where-you-live-and-who-your-neighbours-are"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            TU Delft AE news
                        </a>
                        ,{' '}
                        <a
                            href="https://www.nytimes.com/2020/02/12/climate/air-pollution-health.html"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            NY Times
                        </a>
                        ,{' '}
                        <a
                            href="https://www.popsci.com/story/environment/air-pollution-state-lines-deaths/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Popular Science
                        </a>
                    </EmojiItem>
                    <EmojiItem emoji="🏗" label="Current Projects">
                        Advancing the science for aviation and climate (
                        <a
                            href="https://www.acacia-project.eu/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            ACACIA
                        </a>
                        ) EU Horizon 2020
                    </EmojiItem>
                </div>
            </div>
        ),
    },
    {
        title: 'Systems-level approaches to sustainable air transportation',
        content: (
            <div>
                <img
                    align="right"
                    className="img-responsive img-thumbnail ms-2 mt-1 mb-2"
                    src={aviationEmissionsAndTheirAtmosphericImpactsImg}
                    alt="Aviation emissions and their atmospheric impacts"
                    width="50%"
                />
                <p>
                    The transition to sustainable transportation and energy
                    requires a combination of regulatory, operational and
                    technological changes, each of which occur on different
                    timelines. In parallel, the atmospheric composition,
                    governed by a nonlinear system of coupled relations, is
                    evolving due to overall emissions’ and other changes. We are
                    interested in analysing different approaches to sustainable
                    aviation and other sectors, taking into account the evolving
                    atmospheric co-benefits and trade-offs in the overall
                    systems-level optimisation. We primarily focus on the
                    long-term sustainable growth of aviation, inclusive of
                    emerging modes of transport, such as civil supersonic
                    aircraft, as well as the effects of alternative fuels such
                    as ammonia and hydrogen.
                </p>
                <div>
                    <EmojiItem emoji="📄" label="References">
                        <a
                            href="https://doi.org/10.1088/1748-9326/abe74d"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Dedoussi (2021)
                        </a>
                        ,{' '}
                        <a
                            href="https://doi.org/10.1088/1748-9326/ab34e3"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Dedoussi et al. (2019)
                        </a>
                        ,{' '}
                        <a
                            href="https://doi.org/10.2514/6.2022-2051"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Quadros et al. (2022b)
                        </a>
                    </EmojiItem>
                    <EmojiItem emoji="🌐" label="Press">
                        <a
                            href="https://www.tudelft.nl/en/stories/articles/airplanes-cross-borders-so-do-their-environmental-effects"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            TU Delft Stories of Science
                        </a>
                    </EmojiItem>
                    <EmojiItem emoji="🏗" label="Current Projects">
                        MDO and regulations for low-boom and environmentally
                        sustainable supersonic aviation (
                        <a
                            href="https://www.h2020moreandless.eu/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            MORE&LESS
                        </a>
                        ) EU Horizon 2020, Sustainable transportation in a
                        changing world: how atmospheric gradients can inform
                        policy and engineering decisions (
                        <a
                            href="https://www.tudelft.nl/en/2020/lr/two-veni-grants-for-aerospace-engineering-researchers"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            SLOPE
                        </a>
                        ) Dutch Research Council (NWO) Veni grant, Reducing
                        Environmental Footprint through transformative
                        Multi-scale Aviation Planning (
                        <a
                            href="https://cordis.europa.eu/project/id/101096698"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            REFMAP
                        </a>
                        ) EU Horizon Europe
                    </EmojiItem>
                </div>
            </div>
        ),
    },
    {
        title: 'Data-driven environmental footprinting',
        content: (
            <div>
                <img
                    align="right"
                    className="img-responsive img-thumbnail ms-2 mt-1 mb-2"
                    src={dataDrivenEnvironmentalAssessmentsImg}
                    alt="Data-driven Environmental Assessments"
                    width="50%"
                />
                <p>
                    Increasingly more and better quality observational data are
                    becoming openly available. These include data from
                    atmospheric measurement stations and satellite observations
                    of atmospheric composition, as well as source (e.g.
                    aircraft) activity data. In addition, utilising low cost
                    sensors it has become easier to obtain information tailored
                    to the source of interest, while at the same time enabling
                    crowd-sourcing of data. We utilise this abundant data
                    towards the improved understanding and monitoring of the
                    atmospheric effects of real-world aviation emissions and
                    possible interventions.
                </p>
                <div>
                    <EmojiItem emoji="📄" label="References">
                        <a
                            href="https://doi.org/10.2514/1.C036763"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Quadros et al. (2022a)
                        </a>
                        ,{' '}
                        <a
                            href="https://doi.org/10.1016/j.jenvman.2021.113917"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Verhoeven and Dedoussi (2022)
                        </a>
                    </EmojiItem>
                    <EmojiItem emoji="🌐" label="Press">
                        <a
                            href="https://www.volkskrant.nl/wetenschap/dankzij-schonere-vliegtuigen-mag-schiphol-straks-toch-weer-groeien-hoe-staat-het-ervoor-met-die-toestellen~b18b8c35/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Volkskrant (in Dutch)
                        </a>
                    </EmojiItem>
                    <EmojiItem emoji="🏗" label="Current Projects">
                        Observation-driven assessment and mitigation of
                        aviation&apos;s near-airport and global environmental
                        footprint (TU Delft{' '}
                        <a
                            href="https://salab.lr.tudelft.nl/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Sustainable Aviation Lab
                        </a>
                        ), Next generation data-driven reference European models
                        and methods towards silent and green aircraft operations
                        around airports (
                        <a
                            href="https://cordis.europa.eu/project/id/101095754"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            NEEDED
                        </a>
                        ) EU Horizon Europe
                    </EmojiItem>
                </div>
            </div>
        ),
    },
];
