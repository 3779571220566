import React from 'react';
import './Header.css';
import logo from './Assets/camb_logo_coloured.svg';
import backLogo from './Assets/camb_logo_monochromatic.svg';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Header() {
    return (
        <header>
            <Container className="pt-4">
                <Row>
                    <Col md={9}>
                        <h1 className="main-title">Dr Irene C. Dedoussi</h1>
                        <h2 className="sub-title text-secondary">
                            Associate Professor - Department of Engineering
                        </h2>
                        <h2 className="sub-title text-secondary">
                            University of Cambridge
                        </h2>
                    </Col>
                    <Col md={3} className="py-4 my-auto">
                        <a
                            href="https://www.cam.ac.uk"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                                src={logo}
                                alt="Cambridge University"
                                className="w-100"
                                onMouseOver={(e) =>
                                    (e.currentTarget.src = backLogo)
                                }
                                onFocus={(e) =>
                                    (e.currentTarget.src = backLogo)
                                }
                                onMouseOut={(e) => (e.currentTarget.src = logo)}
                                onBlur={(e) => (e.currentTarget.src = logo)}
                            />
                        </a>
                    </Col>
                </Row>
            </Container>
        </header>
    );
}
