import React from 'react';
import PropTypes from 'prop-types';
import Home from './Home/Home';
import { Research, News } from './Research';
import Publications from './Publications/Publications';
import Teaching from './Teaching/Teaching';
import Contact from './Contact/Contact';
import Links from '../components/Links/Links';

import portrait from './Home/Assets/portrait.jpg';
import entrance from './Contact/Assets/whittle-logo.png';
import CardListGroup from '../components/CardListGroup/CardListGroup';
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';

function Image(props) {
    const { src, alt } = props;
    return <img src={src} width="100%" height="auto" alt={alt} />;
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
};

const contentItems = [
    {
        key: 'home',
        text: <Home />,
        media: <Image src={portrait} alt="portrait" />,
    },
    {
        key: 'research',
        text: <Research />,
        media: (
            <CardListGroup header="In the news">
                {News.map((newsEntry, index) => (
                    <ListGroup.Item key={index} className="overflow-auto">
                        <strong>{newsEntry.title}</strong>
                        <br />
                        Covered by:
                        <br />
                        {newsEntry.media.map((mediaEntry) => (
                            <a
                                href={mediaEntry.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                key={mediaEntry.name}
                            >
                                <Badge bg="secondary" className="me-1">
                                    {mediaEntry.name}
                                </Badge>
                            </a>
                        ))}
                    </ListGroup.Item>
                ))}
            </CardListGroup>
        ),
    },
    {
        key: 'publications',
        text: <Publications />,
        media: (
            <Links
                links={[
                    {
                        url:
                            'https://scholar.google.com/citations?user=sEqpf0wAAAAJ&hl=en',
                        title: 'Google Scholar',
                    },
                    {
                        url:
                            'https://www.scopus.com/authid/detail.uri?authorId=55849974800',
                        title: 'Scopus',
                    },
                    {
                        url:
                            'https://www.researchgate.net/profile/Irene_Dedoussi',
                        title: 'ResearchGate',
                    },
                ]}
            />
        ),
    },
    { key: 'teaching', text: <Teaching /> },
    {
        key: 'contact',
        text: <Contact />,
        media: <Image src={entrance} alt="Whittle Laboratory" />,
    },
];

export default contentItems;
