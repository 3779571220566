import React from 'react';
import './CardListGroup.scss';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';

export default function CardListGroup(props) {
    return (
        <Card>
            <Card.Header className="card-list-group-header">
                <h4 className="my-0">{props.header}</h4>
            </Card.Header>
            <ListGroup variant="flush">{props.children}</ListGroup>
        </Card>
    );
}

CardListGroup.propTypes = {
    header: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.node).isRequired,
};
