export const News = [
    {
        title: 'Aviation’s nitrogen deposition',
        media: [
            {
                name: 'TU Delft News',
                url:
                    'https://www.tudelft.nl/en/2022/lr/most-nitrogen-deposition-from-aviation-comes-from-high-altitude',
            },
            {
                name: 'Luchtvaart Nieuws',
                url:
                    'https://www.luchtvaartnieuws.nl/nieuws/categorie/15/general-av/tu-delft-luchtvaart-verantwoordelijk-voor-12-procent-stikstofneerslag-wereldwijd',
            },
        ],
    },
    {
        title: 'Forbes 30 under 30',
        media: [
            {
                name: 'Forbes',
                url:
                    'https://www.forbes.com/sites/leahrosenbaum/2020/03/17/30-under-30-europe-2020-innovators-in-science-and-health-across-the-continent/',
            },
            {
                name: 'Capital.gr',
                url:
                    'https://www.capital.gr/forbes/3439151/eirina-dedousi-i-ellinida-pou-meletise-tis-epiptoseis-tou-skandalou-volkswagen',
            },
            {
                name: 'CUED News',
                url:
                    'http://www.eng.cam.ac.uk/news/alumna-and-aerospace-engineer-honoured-forbes-30-under-30-list',
            },
            {
                name: 'TU Delft News',
                url:
                    'https://www.tudelft.nl/en/2020/lr/dedoussi-on-forbes-30-under-30-europe-list/',
            },
        ],
    },
    {
        title: 'Cross-state air pollution',
        media: [
            {
                name: 'NY Times',
                url:
                    'https://www.nytimes.com/2020/02/12/climate/air-pollution-health.html',
            },
            {
                name: 'CNN',
                url:
                    'https://edition.cnn.com/2020/02/12/health/premature-mortality-cross-state-air-pollution-study/index.html',
            },
            {
                name: 'Science',
                url:
                    'https://www.sciencemag.org/news/2020/02/deadly-air-pollution-blowing-your-state-surprisingly-large-source',
            },
            {
                name: 'PopSci',
                url:
                    'https://www.popsci.com/story/environment/air-pollution-state-lines-deaths/',
            },
            {
                name: 'MIT News',
                url:
                    'http://news.mit.edu/2020/half-us-deaths-air-pollution-out-state-0212',
            },
            {
                name: 'Forbes',
                url:
                    'https://www.forbes.com/sites/claryestes/2020/02/20/trouble-breathing-the-culprit-could-be-from-out-of-state/',
            },
            {
                name: 'Bloomberg',
                url:
                    'https://www.bloomberg.com/news/articles/2020-02-12/new-yorkers-are-dying-from-air-pollution-caused-by-other-states',
            },
            {
                name: 'TU Delft News',
                url:
                    'https://www.tudelft.nl/en/2020/tu-delft/health-problems-from-air-pollution-often-originate-from-distant-emissions',
            },
        ],
    },
];
